import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, Link, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Grid from '@mui/material/Grid2';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';


const APIURL = process.env.REACT_APP_APIURL;

function Innsendinger() {
    const token = sessionStorage.getItem("jvtoken") || "";
    const minvirksomhetnavn = sessionStorage.getItem("jvaktornavn") || "";
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingInfo, setisLoadingInfo] = useState("");
    const [aktorer, setAktorer] = useState<any[]>([]);
    const [ModalNyAktor, setModalNyAktor] = useState(false);
    const [ModalBeOmTilgang, setModalBeOmTilgang] = useState(false);
    const [ModalOSS, setModalOSS] = useState(false);
    const [TilgangOrgNr, setTilgangOrgNr] = useState(0);
    const [TilgangTilAktorId, setTilgangTilAktorId] = useState(0);
    const [TilgangServicekode, setTilgangServicekode] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [sokinfo, setSokinfo] = useState("");


    interface FormInterfaceOrgNummerSok {
        OrgNummer: string;
    }
    const FormValuesOrgNummerSok: FormInterfaceOrgNummerSok = {
        OrgNummer: "",
    };

    const FormValidationOrgNummerSok = yup.object({
        OrgNummer: yup.string()
            .matches(/^[0-9]{9}$/, 'Organisasjonsnummer må være 9 siffer')
    });

    interface FormInterfaceNameSok {
        Navn: string;
    }
    const FormValuesNameSok: FormInterfaceNameSok = {
        Navn: "",
    };

    const FormValidationNameSok = yup.object({
        Navn: yup.string()
            .matches(/^[a-zA-ZæøåÆØÅ0-9 .-_&/]*$/, 'Navn kan kun inneholde bokstaver, tall, punktum, bindestrek, mellomrom, & og /')
    });

    interface FormInterfaceSaksnummerSok {
        Saksnummer: string;
    }
    const FormValuesSaksnummerSok: FormInterfaceSaksnummerSok = {
        Saksnummer: "",
    };

    const FormValidationSaksnummerSok = yup.object({
        Saksnummer: yup.string()
        .matches(/^(19|20)\d{2}\/([1-9]\d{0,6}|100000)$/, 'Saksnummer må være på format ÅÅÅÅ/####, f.eks. 2024/1234')
    });

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const ModalOSSAapne = () => {
        setModalOSS(true);
    }

    const ModalOSSLukk = () => {
        setModalOSS(false);
    }

    const ModalNyAktorLukk = () => {
        setModalNyAktor(false);
    };

    const modalBeOmTilgangAapne = (orgnr: number, aktorid: number) => {
        setModalBeOmTilgang(true);
        setTilgangOrgNr(orgnr);
        setTilgangTilAktorId(aktorid);
    }
    const modalBeOmTilgangLukk = () => {
        setModalBeOmTilgang(false);
    }

    const handleTilgangKodeChange = (value: string) => {
        setTilgangServicekode(value);
    }

    const ModalNyAktorAapne = () => {
        setModalNyAktor(true);
    };

    const handleSubmitOrgNummerSok = (
        values: FormInterfaceOrgNummerSok,
    ) => {
        setisLoadingInfo("Søker etter virksomhet med organisasjonsnummer " + values.OrgNummer + "...");
        setisLoading(true);
        var formdata = {
            orgnr: values.OrgNummer
        };
        axios.post(APIURL + "/innsending/sokvirksomhetorgnr", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                    setSokinfo("Søk på organisasjonsnummer " + values.OrgNummer + " ga " + response.data.length + " treff.");
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
                setisLoadingInfo("");
            });
    }

    const handleSubmitNameSok = (
        values: FormInterfaceNameSok,
    ) => {
        setisLoadingInfo("Søker etter virksomhet med navn " + values.Navn + "...");
        setisLoading(true);
        var formdata = {
            navn: values.Navn
        };
        axios.post(APIURL + "/innsending/sokvirksomhetnavn", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                    setSokinfo("Søk på navn " + values.Navn + " ga " + response.data.length + " treff.");
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
                setisLoadingInfo("");
            });
    }


    const handleSubmitSaksnummerSok = (
        values: FormInterfaceSaksnummerSok,
    ) => {
        setisLoadingInfo("Søker etter virksomhet med saksnummer " + values.Saksnummer + "...");
        setisLoading(true);
        var formdata = {
            saksnummer: values.Saksnummer
        };
        axios.post(APIURL + "/innsending/sokvirksomhetsaksnummer", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                    setSokinfo("Søk på saksnummer " + values.Saksnummer + " ga " + response.data.length + " treff.");
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
                setisLoadingInfo("");
            });
    }

    const handleTilgangServicekode = () => {
        axios.post(APIURL + "/innsending/virksomhettilgangservicekode", { TilgangTilAktorId: TilgangTilAktorId, ServiceKode: TilgangServicekode }, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.assign("/innsending/lokasjoner/" + TilgangOrgNr);
                }
            })
            .catch((e: any) => {
                if (e.status === 403) {
                    setModalBeOmTilgang(false);
                    setSnackMelding("Feil servicekode oppgitt.");
                    setOpenSnackError(true);
                }
                console.log(e);
            });
    }

    useEffect(() => {
        document.title = 'DinSide - Justervesenet';
        setisLoading(false);
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" /> &nbsp; &nbsp; {isLoadingInfo}
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                Innmeldinger
                            </Typography>
                        </Breadcrumbs>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Dialog open={ModalNyAktor} onClose={ModalNyAktorLukk} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                Virksomheten er ikke registrert hos Justervesenet
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                    Eier av virksomheten må registrere virksomheten hos Justervesenet, og delegere tilgang til din virksomhet, eller oppgi servicekode som du kan benytte for å få tilgang.
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={ModalNyAktorLukk}
                                >
                                    Lukk
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={ModalBeOmTilgang} onClose={modalBeOmTilgangLukk} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                Tilgang til virksomhet [{TilgangOrgNr}]
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                    For å få tilgang til denne virksomheten, må du enten kontakte virksomheten og få de til å gi deg tilgang, eller du be om servicekoden til virksomheten og oppgi denne her:
                                </Typography>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => handleTilgangKodeChange(e.target.value)}
                                    autoFocus
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#eeeeee',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#eeeeee',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#eeeeee',
                                            },
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '24px',
                                            padding: '10px',
                                        },
                                        mb: 2
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleTilgangServicekode()}
                                    sx={{ mr: 2 }}
                                >
                                    Bekreft kode
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={modalBeOmTilgangLukk}
                                >
                                    Avbryt
                                </Button>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalOSS}
                            onClose={ModalOSSLukk}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="oss" />
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2">
                                    <Text tid="oss1tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss1tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss2tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss2tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss3tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss3tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss4tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss4tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss5tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss5tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss6tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss6tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss7tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss7tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss8tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss8tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss9tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss9tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss10tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss10tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss11tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss11tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss12tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss12tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss13tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss13tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss14tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss14tekst" />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Text tid="oss15tittel" />
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Text tid="oss15tekst" />
                                </Typography>
                            </DialogContent>
                        </Dialog>


                        <Grid container spacing={2} p={2}>
                            <Grid size={12}>
                                <Box pb={2}>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="info"
                                        onClick={ModalOSSAapne}
                                        sx={{ mr: 1 }}
                                    >
                                        <Text tid="oss" />
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="info"
                                        href="https://kvalitet.justervesenet.no/docs/pub/DOK01112.pdf"
                                    >
                                        <Text tid="brukerveiledning" />
                                    </Button>
                                </Box>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    <SearchIcon className='jvcardtitleicon' /> Søk etter aktør
                                </Typography>

                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container>
                                            <Grid size={{ xs: 12, sm: 4 }} sx={{ pr: 2 }}>
                                                <Formik
                                                    initialValues={FormValuesOrgNummerSok}
                                                    validationSchema={FormValidationOrgNummerSok}
                                                    onSubmit={handleSubmitOrgNummerSok}
                                                >
                                                    {({ errors, touched, setFieldValue }) => (
                                                        <Form>
                                                            <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                                <InputLabel htmlFor="OrgNummer">
                                                                    <Text tid="sokorgnummer" />
                                                                </InputLabel>
                                                                <Field
                                                                    as={Input}
                                                                    id="OrgNummer"
                                                                    name="OrgNummer"
                                                                    fullWidth
                                                                    required
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                                                        setFieldValue("OrgNummer", numericValue);
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength: 9,
                                                                        pattern: "[0-9]*"
                                                                    }}
                                                                    error={touched.OrgNummer && !!errors?.OrgNummer}
                                                                />
                                                                <ErrorMessage name="OrgNummer" component="div" className="input-error-message" />
                                                            </FormControl>
                                                            <Button
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                color="success"
                                                            >
                                                                <SearchIcon className='jvbtnicon' />
                                                                <Text tid="sok" />
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                            <Grid size={{ xs: 12, sm: 4 }} sx={{ pr: 2 }}>
                                                <Formik
                                                    initialValues={FormValuesNameSok}
                                                    validationSchema={FormValidationNameSok}
                                                    onSubmit={handleSubmitNameSok}
                                                >
                                                    {({ errors, touched, setFieldValue }) => (
                                                        <Form>
                                                            <Box component="div" sx={{ mb: 5 }}>
                                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                                    <InputLabel htmlFor="Navn">
                                                                        <Text tid="virksomhetsnavn" />
                                                                    </InputLabel>
                                                                    <Field
                                                                        as={Input}
                                                                        id="Navn"
                                                                        name="Navn"
                                                                        fullWidth
                                                                        required
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            const numericValue = e.target.value.replace(/[^a-zA-ZæøåÆØÅ0-9 .-_&]/g, '');
                                                                            setFieldValue("Navn", numericValue);
                                                                        }}
                                                                        inputProps={{
                                                                            maxLength: 50,
                                                                            pattern: "[a-zA-ZæøåÆØÅ0-9 .-_&]*"
                                                                        }}
                                                                        error={touched.Navn && !!errors?.Navn}
                                                                    />
                                                                    <ErrorMessage name="OrgNummer" component="div" className="input-error-message" />
                                                                </FormControl>
                                                                <Button
                                                                    size="small"
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="success"
                                                                >
                                                                    <SearchIcon className='jvbtnicon' />
                                                                    <Text tid="sok" />
                                                                </Button>
                                                            </Box>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                            <Grid size={{ xs: 12, sm: 4 }} sx={{ pr: 2 }}>
                                                <Formik
                                                    initialValues={FormValuesSaksnummerSok}
                                                    validationSchema={FormValidationSaksnummerSok}
                                                    onSubmit={handleSubmitSaksnummerSok}
                                                >
                                                    {({ errors, touched, setFieldValue }) => (
                                                        <Form>
                                                            <Box component="div" sx={{ mb: 5 }}>
                                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                                    <InputLabel htmlFor="Saksnummer">
                                                                        <Text tid="saksnummer" />
                                                                    </InputLabel>
                                                                    <Field
                                                                        as={Input}
                                                                        id="Saksnummer"
                                                                        name="Saksnummer"
                                                                        fullWidth
                                                                        required
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            const saksvalue = e.target.value.replace(/[^0-9/]/g, '');
                                                                            setFieldValue("Saksnummer", saksvalue);
                                                                        }}
                                                                        inputProps={{
                                                                            maxLength: 12,
                                                                            pattern: "^(19|20)\\d{2}/\\d{1,10}$"
                                                                        }}
                                                                        error={touched.Saksnummer && !!errors?.Saksnummer}
                                                                    />
                                                                    <ErrorMessage name="Saksnummer" component="div" className="input-error-message" />
                                                                </FormControl>
                                                                <Button
                                                                    size="small"
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="success"
                                                                >
                                                                    <SearchIcon className='jvbtnicon' />
                                                                    <Text tid="sok" />
                                                                </Button>
                                                            </Box>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid size={12}>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    <SearchIcon className='jvcardtitleicon' /> <Text tid="soketreff" /> {(sokinfo) && <Typography style={{ marginLeft: '10px' }}>({sokinfo})</Typography>}
                                </Typography>
                                {((aktorer[0]?.resultater) && (aktorer[0]?.resultater > 10)) && <Typography variant="body2"><i><Text tid="flereenntifunn" /></i></Typography>}
                                <p></p>
                                {aktorer && aktorer.map(({ orgnr, navn, forretningsadr, forradrpostnr, forradrpoststed, forradrkommnavn, MetricAktorId, MetricAktiv, MetricAntallUnderenheter, MetricAktorAktorTilgang }: any, index: number) => {
                                    return <span key={index}>
                                        {(MetricAktorId) ?
                                            <Link
                                                href={
                                                    (MetricAktiv && MetricAktorAktorTilgang) ?
                                                        "/innsending/lokasjoner/" + orgnr : "#"
                                                }
                                                underline="none"
                                                onClick={(MetricAktiv && !MetricAktorAktorTilgang) ? () => { modalBeOmTilgangAapne(orgnr, MetricAktorId) } : undefined}
                                            >
                                                <Card variant="outlined" className={(MetricAktiv) ? "CardLinkA" : "CardLinkNA"} sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {navn} &nbsp; [{orgnr}]
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                            <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                        </Typography>
                                                        <p></p>
                                                        {(MetricAktorId) ?
                                                            (MetricAktiv) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> Registrert hos Justervesenet
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="error" /> Registrert hos Justervesenet, men ikke aktiv
                                                                </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> Ikke registrert hos Justervesenet
                                                            </Typography>
                                                        }
                                                        {(MetricAntallUnderenheter !== 0) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> {MetricAntallUnderenheter} aktive underenheter
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> ingen aktive underenheter
                                                            </Typography>
                                                        }
                                                        {(MetricAktorAktorTilgang) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> {minvirksomhetnavn} har tilgang til denne virksomheten
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> {minvirksomhetnavn} har ingen tilgang til denne virksomheten
                                                            </Typography>
                                                        }
                                                        <p></p>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                            :
                                            <Link
                                                href="#"
                                                underline="none"
                                                onClick={() => { ModalNyAktorAapne() }}
                                            >
                                                <Card variant="outlined" className="CardLinkNA" sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {navn} &nbsp; [{orgnr}]
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                            <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                        </Typography>
                                                        <p></p>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                            <DisabledByDefaultIcon className='jvtexticon' color="error" /> Ikke registrert hos Justervesenet
                                                        </Typography>
                                                        <p></p>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                        }
                                    </span>
                                })
                                }
                            </Grid>
                        </Grid>




                    </div>
            }
        </main >
    )
}

export default Innsendinger;