import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from './sider/Login'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LanguageProvider } from './komponenter/Tekst';
import { Theme } from './komponenter/Tema';
import { CssBaseline } from '@mui/material';
import Forsiden from './sider/Forsiden';
import Loggut from './sider/Loggut';
import Profil from './sider/Profil';
import Taksameter from './sider/Taxi';
import TaksameterDetaljer from './sider/TaxiDetaljer';
import Nykonto from './sider/Nykonto';
import GlemtPassord from './sider/GlemtPassord';
import Aarsavgift from './sider/Aarsavgift';
import AarsavgiftBensin from './sider/AarsavgitBensin';
import Admin from './sider/Admin';
import Arkiv from './sider/Arkiv';
import Innsending from './sider/Innsending';
import InnsendingLokasjoner from './sider/InnsendingLokasjoner';
import InnsendingDetaljer from './sider/InnsendingDetaljer';

const App = () => {
  return (
    <Theme>
      <CssBaseline />
      <LanguageProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Forsiden />} />
            <Route path="/logginn" element={<Login />} />
            <Route path="/nykonto" element={<Nykonto />} />
            <Route path="/glemtpassord" element={<GlemtPassord />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/taksameter" element={<Taksameter />} />
            <Route path="/taksameter/:orgnr" element={<TaksameterDetaljer />} />
            <Route path="/aarsavgift" element={<Aarsavgift />} />
            <Route path="/aarsavgift/bensin/:orgnr" element={<AarsavgiftBensin />} />
            <Route path="/loggut" element={<Loggut />} />
            <Route path="/admin/:orgnr" element={<Admin />} />
            <Route path="/innsending" element={<Innsending />} />
            <Route path="/innsending/lokasjoner/:orgnr" element={<InnsendingLokasjoner />} />
            <Route path="/innsending/:aktorid" element={<InnsendingDetaljer />} />
            <Route path="/arkiv" element={<Arkiv />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </LanguageProvider >
    </Theme>
  )
}
export default App